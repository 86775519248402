.LeftNavUser {
  $self: &;
  display: flex;
  justify-content: center;

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;

    #{$self}__img {
      fill: white;
      width: 25px;
      height: 25px;
      border-radius: 21px;
    }

    #{$self}__name {
      margin-left: 8px;
      color: white;
      line-height: 16px;
      font-size: 13px;
      font-family: var(--font-family-segoe);
      width: 100px;
      text-align: center;
    }
  }
}
