.navLink.active > .listItem,
.navLink.active .navLink--icon {
  background-color: white !important;
  color: #061f76 !important;
}

.navLink.active .MuiListItemText-primary {
  font-weight: 600;
}
.profile__section {
  .MuiGrid-root {
    padding: 4px 16px !important;
  }
}
.resource-link {
  color: white;
  font-size: 12px;
}

