.background {
  background: white;
}

.LogoImg {
  width: 250px;
  text-align: center;
}

.UserImg {
  width: 42px;
  height: 42px;
  border-radius: 21px;
  margin-bottom: -15px;
  margin-right: 12px;
}

@media (max-width: 1280px) {
  .LogoImg {
    width: 200px;
    text-align: center;
  }
}

@media (min-width: 1280px) {
  .MuiContainer-maxWidthLg {
    max-width: 1450px !important;
  }
}
