.publisher--content {
  .slug--suggestion {
    padding-top: 2px;
    .red-text {
      color: red;
      font-size: 12px;
    }
    .MuiButtonBase-root.MuiChip-root {
      &:hover {
        background-color: #08289ae6;
        color: white;
      }
    }
  }
}
