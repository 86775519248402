.Login {
  display: grid;
  place-items: center;
  height: 100vh;
  background-color: #191a35;

  @media (max-width: 600px) {
    height: unset;
    display: block;
    overflow-y: scroll;
  }
}
