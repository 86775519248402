.RootLevelScreens_item--container .screen-name {
  color: white;
  font-size: 0.875rem !important;
  font-family: 'Inter', sans-serif !important;
  font-weight: 400;
  margin-left: 10px;
 
}

.RootLevelScreens_item--container {
  text-decoration: none;
  text-align: left;
  color:white;
  height: 35px;
}
.RootLevelScreens_item--container svg{
    fill: white;
    padding-top: 5px;
    height: 45px;
}
.RootLevelScreens_item--container {
  padding-left: 32px;
  color: white !important;
  width: 100%;
  display: flex;
  align-items: center;
  line-height: 1.5;
}
