@import "font";
@import "colors";
@import "core";

html,
body,
#root,
.makeStyles-root-5 {
  height: 100% !important;
  font-size: 14px;
}
.collapse:not(.show) {
  display: none;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7c7c7c;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(177, 177, 177);
}

.LayoutBackground {
  background: #08289a !important;
}

.MenuPublisher {
  height: 35px;
}

.FixedMenu {
  position: relative;
  height: 70%;
  overflow-y: auto;
  overflow-x: hidden;
}

.CollapseNested {
  position: relative;
  max-height: 200 !important;
  background: #061f76 !important;
}

.CollapseNested ::-webkit-scrollbar {
  width: 2px !important;
  height: 2px !important;
}

.MuiTableCell-root {
  border-bottom: 0px solid rgba(224, 224, 224, 1) !important;
  font-family: "Inter", sans-serif !important;
  flex-direction: row-reverse !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.HeaderMargin {
  margin-bottom: -3px;
}

.TextBlue {
  color: #08289a !important;
}

.MarginHeader {
  margin-top: 30px;
}

.DialogMargin {
  margin-top: 5px;
}

.InputRadius {
  border: 1px solid #08289a;
  padding: 2px 10px;
  border-radius: 18px;
}

.MuiTab-root {
  text-transform: none !important;
  color: #08289a !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  min-width: 72px !important;
  font-family: "Inter", sans-serif !important;
}

.PrivateTabIndicator-colorSecondary-16 {
  background-color: #08289a !important;
}

.Ellipsis {
  max-width: 200px; // percentage also works
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.EllipsisPreview {
  max-width: 400px; // percentage also works
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MuiButton-root {
  text-transform: none !important;
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.02) !important;
}

.MousePointer {
  cursor: pointer;
}

.FloatRight {
  float: right;
}

.PaddingClose {
  padding: 6px 7px 0px 0px !important;
}

.BorderBottom {
  border-bottom: 2px solid #061f76;
}

.Theme {
  border-radius: 18px !important;
  color: white !important;
  text-transform: none !important;
}

.FilterHeader {
  margin: 50px 0px 20px 0px !important;
}

.FilterKeyword {
  margin: 20px 0px 10px 0px !important;
}

.rootHeight {
  height: calc(100vh - 100px) !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.ColorText {
  color: #7a7a7a;
}

.tile-img {
  padding: 10px 10px 10px 10px;
  width: 100%;
  border-radius: 15px;
}

.tile-img:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.MuiInputBase-input.Mui-disabled {
  background: #cccccc40 !important;
  color: black !important;
}

.MuiTabPanel-root {
  padding: 1px !important;
}

.MuiDialogActions-root {
  border-top: 1px solid lightgray;
}

.MuiDialogTitle-root {
  border-bottom: 1px solid lightgray;
  padding: 16px;
}

.pagination-bar {
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.pagination-item {
  padding: 1px;
  border: 1px solid;
  border-radius: 50%;
  background-color: #0000ff40;
  cursor: pointer;
  width: 25px;
  height: 25px;
  margin: 10px;
}

.active {
  padding: 1px;
  color: blue;
  cursor: pointer;
  font-weight: 700;
  margin: 10px;
}

.disabled-item {
  padding: 1px;
  border: 1px solid;
  border-radius: 50%;
  background-color: #cccccc;
  cursor: pointer;
  width: 25px;
  height: 25px;
  margin: 10px;
}

.page-item {
  padding: 1px;
  cursor: pointer;
  margin: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.MuiTabScrollButton-root {
  width: 20px !important;
}

@media (min-width: 1200px), (min-width: 960px) and (-webkit-device-pixel-ratio: 1.25) {
}

@media (-webkit-device-pixel-ratio: 1.25) {
  * {
    zoom: 1;
  }
}

.rdg-row-even {
  background-color: #f2f2f2;
}

.rdg-row-inactive > .rdg-cell {
  opacity: 0.5;
}

.linkCopy-Child {
  position: absolute;
  background: white;
  right: 0;
  display: none;
  font-size: xx-small;
}

.rdg-cell.right-align {
  text-align: right;
}

.rdg-cell:hover .linkCopy-Child {
  display: inline-block;
}

.MuiDialog-container {
  background-color: rgba(0, 0, 0, 0.3);
}

.rdg-row-emptyRowMessage {
  text-align: center;
  grid-column: 1/-1;
  padding: 8px 0;
}
