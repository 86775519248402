.LoginBox {
  $self: &;
  display: flex;
  // justify-content: space-evenly;
  justify-content: space-evenly;
  flex-wrap: wrap;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  border: 1px solid #606060;
  width: 800px;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // width: 300px;

    #{$self}__logo-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      #{$self}__logo {
        max-width: 100px;
        height: auto;
      }
    }

    #{$self}__heading {
      color: #111111;
      font-size: 19px;
      line-height: 26px;
      font-weight: 400;
      font-family: var(--font-family-segoe);
      justify-self: center;
      margin-top: 10px;
    }

    #{$self}__description {
      font-size: 15px;
      font-weight: 400;
      line-height: 120%;
      color: #767676;
      justify-self: center;
      margin-top: 5px;
    }

    #{$self}__sign-in {
      display: flex;
      // justify-content: flex-start;
      align-items: center;
      box-shadow: 2px 2px 3px 0 rgba(117, 124, 129, 0.12);
      // padding-left: 4rem;
      color: #6d7780;
      font-size: 0.875rem;
      cursor: pointer;
      margin-top: 10px;
      min-width: 300px;
      width: 100%;

      &:hover {
        background: #f7f7f8;
      }

      #{$self}__social-logo {
        &--google {
          width: 50px;
        }

        &--wrapper {
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &--continue {
          width: 24px;
          color: #237aff;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &--facebook {
          width: 24px;
          // margin-right: 13px;
        }

        &--email {
          font-size: 25px;
        }
      }

      #{$self}__sign-in-text {
        font-family: Helvetica, Arial, sans-serif;
        &--continue {
          margin-left: 5px;
        }
      }

      &--google,
      &--facebook,
      &--email,
      &--continue,
      &--sim {
        padding-left: 3rem;
      }

      // &--facebook,
      // &--email {
      //   height: 50px;
      // }
    }

    #{$self}__terms-conditions-contact {
      justify-self: center;

      #{$self}__agree-terms {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        margin-bottom: 2px;
      }

      #{$self}__terms {
        font-size: 10px;
        font-weight: 400;
        letter-spacing: 0.75px;
        color: #c4c4c4;
        margin-top: 5px;
        margin-bottom: 10px;
        margin-left: 2px;
        margin-right: 2px;
        cursor: pointer;
      }

      #{$self}__privacy-policy {
        margin: 0 0 0 10px;
      }

      #{$self}__contact-us {
        display: flex;
        justify-content: center;
      }
    }
  }

  @media (max-width: 1024px) {
    width: 90%;

    &__img {
      margin-top: 20px;
    }

    &__content {
      #{$self}__logo-container {
        width: 90%;
      }
    }
  }

  @media (max-width: 600px) {
    overflow-y: scroll;
    margin: 20px auto;
  }
}

.LoginBox2 {
  $self: &;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  border-radius: 30px;
  border: 1px solid #707070;
  width: 1250px;
  // height: 475px;
  // width: 45%;
  // height: 50%;
  background-color: white;
  padding: 4rem 1.5rem;
  position: relative;
  z-index: 2;

  &__wrapper {
    width: 830px;
    height: 475px;
    // width: 45%;
    // height: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    #{$self}__top-left-big-green {
      width: 157px;
      height: 157px;
      border-radius: 50%;
      background-color: #d4f9bb;
      position: absolute;
      top: -65px;
      left: -65px;
    }

    #{$self}__bottom-right-small-green {
      width: 23px;
      height: 23px;
      border-radius: 50%;
      background-color: #d4f9bb;
      position: absolute;
      right: -50px;
      bottom: 150px;
    }
  }

  &__img-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    #{$self}__logo-container {
      text-align: center;
    }

    #{$self}__logo {
      max-width: 250px;
      height: auto;
    }

    #{$self}__img {
      max-width: 778px;
      max-height: 280px;
      min-width: 137px;
      min-height: 130.33px;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__content {
    #{$self}__title {
      color: #237aff;
      font-family: var(--font-family-segoe);
      font-weight: 700;
      font-size: 24px;
      line-height: 26px;
    }

    #{$self}__description {
      color: #767676;
      font-family: var(--font-family-segoe);
      font-weight: 400;
      font-size: 12px;
      line-height: 21px;
      margin-top: 5px;
    }

    #{$self}__tabs {
      margin-bottom: 1em;

      #{$self}__tabs-header {
        display: flex;

        #{$self}__tabs-heading {
          color: #201000;
          font-family: var(--font-family-segoe);
          font-weight: 400;
          font-size: 18px;
          line-height: 26px;
          cursor: pointer;
          width: 50%;
          text-align: center;
          padding-bottom: 10px;

          &--active {
            color: #237aff;
            border-bottom: 1px solid #237aff;
            font-weight: 700;
          }
        }
      }
    }

    #{$self}__terms-conditions-contact {
      justify-self: center;
      margin-top: 1em;

      #{$self}__agree-terms {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      #{$self}__terms {
        font-size: 10px;
        font-weight: 400;
        letter-spacing: 0.75px;
        color: #c4c4c4;
        margin: 0 4px;
        cursor: pointer;

        &--highlight {
          color: #237aff;
          text-decoration: underline;
        }
      }

      #{$self}__privacy-policy {
        margin-left: 0px;
      }

      #{$self}__contact-us {
        display: flex;
        justify-content: center;
        margin-top: 5px;
      }
    }
  }

  &__bottom-right-big-pink {
    width: 157px;
    height: 157px;
    border-radius: 50%;
    background-color: #ef7d7c;
    position: absolute;
    right: -65.5px;
    bottom: -65.5px;
  }

  &__top-left-medium-green {
    width: 43px;
    height: 43px;
    border-radius: 50%;
    background-color: #d4f9bb;
    position: absolute;
    left: -22.5px;
    top: 180px;
  }
  &__top-left-medium-pink {
    width: 43px;
    height: 43px;
    border-radius: 50%;
    background-color: #ef7d7c;
    position: absolute;
    top: 90px;
    left: -90px;
  }

  @media (max-width: 1024px) {
    width: 50%;

    &__wrapper {
      width: 50%;
      height: 58%;
    }

    &__img-content {
      #{$self}__img {
        width: 100%;
        height: 60%;
      }
    }

    &__content {
      width: 100%;

      #{$self}__title {
        font-size: 26px;
      }

      #{$self}__description {
        font-size: 14px;
      }

      #{$self}__terms-conditions-contact {
        #{$self}__agree-terms {
          align-items: start;
        }

        #{$self}__terms {
          font-size: 12px;
          text-align: center;
        }
      }
    }
  }

  @media (max-width: 768px) {
    width: 65%;
    height: 85%;

    &__wrapper {
      width: 65%;
      height: 80%;
    }
  }

  @media (max-width: 600px) {
    margin: 70px auto;
    width: 85%;
    height: 80%;
    margin-bottom: 7rem;

    &__wrapper {
      width: 85%;
      height: 80%;

      #{$self}__top-left-big-green {
        top: -55px;
        left: 15px;
        width: 111px;
        height: 111px;
      }

      #{$self}__bottom-right-small-green {
        right: unset;
        bottom: unset;
        left: -20px;
        top: 80px;
        width: 43px;
        height: 43px;
      }
    }

    // &__img-content {
    //   #{$self}__img {
    //     width: 137px;
    //     height: 130.33px;
    //   }
    // }

    &__content {
      width: 100%;

      #{$self}__title {
        font-size: 20px;
        text-align: center;
      }

      #{$self}__environmentName {
        font-size: 20px;
        text-align: center;
      }

      #{$self}__description {
        font-size: 14px;
        text-align: center;
      }

      #{$self}__tabs {
        #{$self}__tabs-header {
          #{$self}__tabs-heading {
            font-size: 16px;
          }
        }
      }
    }

    &__bottom-right-big-pink {
      right: -15px;
      bottom: -15px;
      width: 61px;
      height: 61px;
    }

    &__top-left-medium-green {
      width: 11px;
      height: 11px;
      left: unset;
      top: unset;
      right: 80px;
      bottom: -20px;
    }
    &__top-left-medium-pink {
      width: 11px;
      height: 11px;
      top: -20px;
      left: 130px;
    }
  }

  @media (max-width: 360px) {
    margin-bottom: 4rem;

    &__wrapper {
      #{$self}__top-left-big-green {
        top: -35px;
      }
    }
  }

  @media (max-width: 320px) {
    &__wrapper {
      #{$self}__top-left-big-green {
        top: -25px;
      }
    }
  }
}

.SocialLogin {
  $self: &;
  margin-top: 8px;
  &__sign-in {
    display: flex;
    align-items: center;
    color: #6d7780;
    cursor: pointer;
    margin-top: 10px;
    min-width: 300px;
    width: 100%;

    border: 1px solid #707070;
    border-radius: 15px;
    font-size: 16px;
    height: 40px;

    &:hover {
      background: #f7f7f8;
    }

    #{$self}__social-logo {
      &--google {
        width: 50px;
      }

      &--wrapper {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &--continue {
        width: 24px;
        color: #237aff;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &--facebook {
        width: 24px;
      }

      &--email {
        font-size: 25px;
      }
    }

    #{$self}__logo {
      max-width: 100px;
      height: auto;
    }

    #{$self}__sign-in-text {
      font-family: Helvetica, Arial, sans-serif;
      &--continue {
        margin-left: 5px;
      }
    }

    &--google,
    &--facebook,
    &--email,
    &--continue,
    &--sim {
      padding-left: 3rem;
    }
  }

  @media (max-width: 600px) {
    &__sign-in {
      min-width: 270px;
      padding-left: 2rem;
    }
  }

  @media (max-width: 360px) {
    height: 150px;

    &__sign-in {
      min-width: 250px;
      padding-left: 1rem;
      font-size: 14px;
    }
  }

  @media (max-width: 320px) {
    &__sign-in {
      min-width: 220px;
      font-size: 12px;
    }
  }
}
